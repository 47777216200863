.image {
  height: 300px;
}
.containers {
  position: relative;
  text-align: center;
  color: white;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.categoryCard{
  margin-top: 20px;
}