/* Dashboard CSS */
.sidenavbtn {
  padding: 20px;
  margin-bottom: 10px;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
}

.sidenavbtn:hover {
  background-color: lightblue;
}

.roundedCard {
  padding: 10px;
  margin-top: 20px;
  text-align: center;
  border: 1px solid black;
  border-radius: 20px;
  cursor: pointer;
}

.roundedCard:hover {
  background-color: lightgray;
}

/* Design Page CSS */
.admin-form {
  padding: 15px;
  border-radius: 6px;
  border: 2px solid #120101;
}

.form-group {
  margin-bottom: 10px;
}

.button {
  border-radius: 15px;
  padding: 5px;
  margin-bottom: 20px;
}
