.centr{
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
}

.divider {
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.shimmer {
    height: 56px;
    width: 56px;
    border-radius: 50%;
}

.rsImg{
    width: 100%;
    height: auto;
}